import validate from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/mcc/src/middleware/redirect.global.ts";
import manifest_45route_45rule from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/mcc/src/middleware/admin.ts"),
  auth: () => import("/mcc/src/middleware/auth.ts"),
  "public-only": () => import("/mcc/src/middleware/public-only.ts")
}