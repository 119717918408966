import revive_payload_client_EWuhP7erK3 from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9WKSzsJRJj from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_397E90MPyY from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_v1rUQUavDj from "/mcc/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.3_@vue+compiler-core@3.4.29_nuxt@3.12.2_postcss@8._zayoqdklg66wbljl55zu2w7dfy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yK1SPqqp2g from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_jzrqyitAi1 from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_b7nNYWISsa from "/mcc/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.29/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/mcc/.nuxt/components.plugin.mjs";
import prefetch_client_W7kDyFMcI2 from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fUEvfpaGmZ from "/mcc/node_modules/.pnpm/@nuxtjs+device@3.1.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import floating_vue_EIcJ7xiw0h from "/mcc/.nuxt/floating-vue.mjs";
import script_hHEPzAINpx from "/mcc/node_modules/.pnpm/@nuxtjs+turnstile@0.6.3/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_QUJKl7CH1E from "/mcc/node_modules/.pnpm/nuxt-directus@5.6.1/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import chunk_reload_client_wUziRA5Qcm from "/mcc/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_pflzlcrhtgm5aayjr6xt4vmija/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import umami_UbpOuIUCD6 from "/mcc/node_modules/.pnpm/nuxt-umami@2.6.2/node_modules/nuxt-umami/plugins/umami.ts";
import authRefresh_client_zjlpzuMCqF from "/mcc/src/plugins/authRefresh.client.ts";
import redirects_oxa5gmVmKq from "/mcc/src/plugins/redirects.ts";
import sentry_client_KAXFuL2wum from "/mcc/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_EWuhP7erK3,
  unhead_9WKSzsJRJj,
  router_397E90MPyY,
  _0_siteConfig_v1rUQUavDj,
  payload_client_yK1SPqqp2g,
  check_outdated_build_client_jzrqyitAi1,
  plugin_vue3_b7nNYWISsa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_W7kDyFMcI2,
  plugin_fUEvfpaGmZ,
  floating_vue_EIcJ7xiw0h,
  script_hHEPzAINpx,
  plugin_QUJKl7CH1E,
  chunk_reload_client_wUziRA5Qcm,
  umami_UbpOuIUCD6,
  authRefresh_client_zjlpzuMCqF,
  redirects_oxa5gmVmKq,
  sentry_client_KAXFuL2wum
]